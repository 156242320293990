// Default Fonts & Colors
$font-stack: 'FFSizmoWebPro', Arial, sans-serif;

$text-color: #000000;
$white-color: #ffffff;

$blmk-grey-65: #595959;
$blmk-grey-60: #666666;
$blmk-grey-45: #8c8c8c;
$blmk-grey-35: #a6a6a6;
$blmk-grey-25: #bfbfbf;
$blmk-grey-10: #dadada;

// Default Widths
$primary-width: 1050px;

// Breakpoints
$breakpoint-full: $primary-width + 40;
$breakpoint-medium: 760px;
$breakpoint-medium-2: 550px;
$breakpoint-small: 480px;

// Clearfix Placeholder Example: @extend %clearfix;
%clearfix {
  *zoom: 1;

  &:before,
  &:after {
      content: ' ';
      display: table;
  }

  &:after {
      clear: both;
  }
}

// Mixin mobile Navigation
@mixin mobile_navi {
  .navi--header {
    margin: 0;

    .navibutton {
      display: block;
      position: absolute;
      top: 20px;
      right: 20px;
      width: 30px;
      height: 30px;
      overflow: hidden;

      .open-navi {
        display: block;
        width: 30px;
        height: 30px;
      }

      .close-navi, span {
        display: none;
      }

      a {
        &:before {
          content: '';
          display: block;
          width: 30px;
          height: 22px;
          border-top: 4px solid $text-color;
          border-bottom: 4px solid $text-color;
        }

        &:after {
          content: '';
          display: block;
          width: 30px;
          height: 1px;
          border-top: 4px solid $text-color;
          position: absolute;
          left: 0;
          top: 13px;
        }
      }
    }

    .navi__inner {
      overflow: hidden;
      max-height: 0;
    }

    .primary, .secondary .menu {
      opacity: 0;
      transition: opacity .5s;
    }

    .primary {
      float: left;
      width: 70%;
      margin-top: 30px;

      .menu {
        display: block;
      }

      .menu-item {
        font-size: 1.625rem;
        display: block;
        padding: 7px 0 14px;
        margin: 0;

        .sub-menu {
          position: static;
          white-space: normal;
          overflow: visible;
          max-height: 10000em;
          opacity: 1;
          padding: 0;
          margin-top: 6px;

          .menu-item {
            display: inline-block;
            padding: 0 0 6px;
            margin-bottom: 0;

            a {
              &:before {
                top: -6px;
              }
            }

            &:after {
              content: ' | ';
            }
          }

          .menu-item:last-child {
            padding-bottom: 0;

            &:after {
              content: '';
            }
          }
        }

        a {
          padding: 0;

          &:before {
            top: -10px;
          }
        }
      }
    }

    .secondary {
      padding-top: 30px;
      position: static;
      float: right;
      text-align: right;
      line-height: 1.25;
      width: 20%;

      .menu {
        display: block;
      }

      .menu-item {
        display: block;
        padding: 7px 0;
        margin-left: 0;
      }

      .social-icon {
        display: inline-block;
        margin: 10px 0 0 6px;

        a {
  				float: right;
  			}
      }

      .venue-button {
        position: absolute;
        top: 20px;
        right: 20px;
      }
    }
  }
}

// Mixin collapsed Navigation
@mixin collapsed_navi  {
  .fixed-current-item {
    opacity: 0;
    transition: opacity 0.25s;
  }

  .close-navi {
    display: block;
    width: 30px;
    height: 30px;
  }

  .open-navi {
    display: none;
  }

  .navi__inner {
    opacity: 1;
    max-height: 10000em;
    transition: opacity .5s;
  }

  .primary, .secondary .menu {
    opacity: 1;
    transition: opacity .5s;
  }

  .navibutton {
    a {
      &:before, &:after {
        border-bottom-color: transparent;
        transform: rotate(45deg);
        height: 0;
        position: absolute;
        top: 13px;
        left: 0;
        transition: transform 0.5s;
      }

      &:before {
        left: 1px;
        transform: rotate(-45deg);
      }
    }
  }
}
