// Local Imports
@import 'vars';

img,
.teaser {
	opacity: 1;
}

.event_gallery .slides,
.list__slider .slides {
	white-space: normal !important;
}

.event_gallery .slides .single-item,
.list__slider .slides .single-item {
	margin-bottom: 30px;
}

#standorte:target,
#venues:target {
  max-height: 10000em;
  opacity: 1;
  transition: max-height 0.25s, opacity 0.5s;
}

#suche:target,
#search:target {
  max-height: 10000em;
  opacity: 1;
  transition: max-height 0.25s, opacity 0.5s;
}

/* ---- Media Queries: Smaller than 1090px ---- */
@media (max-width: $breakpoint-full) {
  #navigation:target {
    @include collapsed_navi;
  }
}
